
import { computed, defineComponent, useStore } from '@nuxtjs/composition-api'
import { stripTags, truncate } from '@zicht/nuxt-util'
import { setupTranslationPrefixer } from '~/assets/util'
import { setupConcertStatus } from '~/assets/i-concert'
import { setupTix } from '~/assets/composition/tix'

export default defineComponent({
  props: {
    body: { type: String, required: false, default: undefined },
    endDate: { type: [String, Date], required: false, default: undefined },
    image: {
      type: Object,
      required: false,
      default: () => ({ _smUrl: 'images/fallback/345x206.png', _smWidth: 908, _smHeight: 545, _mdUrl: 'images/fallback/463x280.png', _mdWidth: 947, _mdHeight: 573 }),
    },
    imgAlt: { type: String, required: false, default: undefined },
    label: { type: String, required: false, default: undefined },
    discount: { type: String, required: false, default: undefined },
    location: { type: String, required: false, default: undefined },
    streamUrl: { type: String, required: false, default: undefined },
    title: { type: String, required: false, default: undefined },
    url: { type: String, required: false, default: undefined },
    when: { type: [String, Date], required: false, default: undefined },
    whenDescription: { type: String, required: false, default: undefined },
    works: { type: Array, required: false, default: () => [] },

    // The card can be used as a placeholder while the underlying data is loading
    loading: { type: Boolean, required: false, default: false },

    // concert status from "IConcert"
    concertStatus: { type: String, required: false, default: '' },
    eventId: { type: Number, required: false, default: undefined },
    // Price information from `setupTixPricing`
    tixPricing: { type: Object, required: false, default: () => ({}) },
    // Sale information from `setupTixSale`
    tixSale: { type: Object, required: false, default: () => ({}) },
  },
  setup(props) {
    const minPrice = computed(() => props.tixPricing?.minPrice?.value)
    const { saleIcon, saleTemplate, saleIsAvailable, saleIsAvailableInFuture, saleAvailableFromDate } = props.tixSale
    const store = useStore()

    const favoritesToggleEventId = (eventIConcertId) => {
      store.commit('favorites/toggleEventIConcertId', eventIConcertId)
    }

    const favoritesOpenOverlay = (eventIConcertId) => {
      store.commit('favorites/openOverlayAndStoreId', eventIConcertId)
    }

    return {
      ...setupTranslationPrefixer('CardEventAndSeries'),
      ...setupConcertStatus(props.concertStatus, 'card-event-and-series'),
      ...setupTix(),
      favoritesEnabled: computed(() => store.getters['favorites/enabled']),
      favoriteEventIConcertIds: computed(() => store.getters['favorites/eventIConcertIds']),
      minPrice,
      saleAvailableFromDate,
      saleIcon,
      saleIsAvailable,
      saleIsAvailableInFuture,
      saleTemplate,
      stripTags,
      truncate,
      favoritesToggleEventId,
      favoritesOpenOverlay,
    }
  },
  computed: {
    sortedWorks() {
      return [...this.works].sort((a, b) => a.featured - b.featured)
    },
  },
})
