import { render, staticRenderFns } from "./MatrixVriendenLoterijVipCard.vue?vue&type=template&id=4b323ec8"
import script from "./MatrixVriendenLoterijVipCard.vue?vue&type=script&lang=js"
export * from "./MatrixVriendenLoterijVipCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomIcon: require('/home/userapp/components/Atom/Icon.vue').default})
